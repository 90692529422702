export const COUNTRIES: Record<string, string[]> = {
    AE: ['United Arab Emirates', 'UAE'],
    AT: ['Austria'],
    AU: ['Australia'],
    AZ: ['Azerbaijan'],
    BE: ['Belgium'],
    BH: ['Bahrain'],
    BR: ['Brazil'],
    CA: ['Canada'],
    CH: ['Switzerland'],
    CL: ['Chile'],
    CN: ['China'],
    // CZ: ['Czech Republic'],
    DE: ['Germany'],
    DK: ['Denmark'],
    EG: ['Egypt'],
    ES: ['Spain'],
    FR: ['France'],
    GB: ['Great Britain', 'UK'],
    GR: ['Greece'],
    HK: ['Hong Kong'],
    // HR: ['Croatia'],
    HU: ['Hungary'],
    ID: ['Indonesia'],
    // IE: ['Republic of Ireland'],
    IL: ['Israel'],
    IN: ['India'],
    IT: ['Italy'],
    JE: ['Jersey'],
    JO: ['Jordan'],
    JP: ['Japan'],
    KH: ['Cambodia'],
    KR: ['South Korea'],
    KW: ['Kuwait'],
    LB: ['Lebanon'],
    LT: ['Lithuania'],
    LU: ['Luxembourg'],
    LV: ['Latvia'],
    MA: ['Morocco'],
    MC: ['Monaco'],
    MO: ['Macau'],
    MX: ['Mexico'],
    MY: ['Malaysia'],
    NL: ['Netherlands'],
    NO: ['Norway'],
    NZ: ['New Zealand'],
    OM: ['Oman'],
    PE: ['Peru'],
    PH: ['Philippines'],
    PL: ['Poland'],
    PT: ['Portugal'],
    QA: ['Qatar'],
    RO: ['Romania'],
    RU: ['Russia'],
    SA: ['Saudi Arabia'],
    SE: ['Sweden'],
    SG: ['Singapore'],
    TH: ['Thailand'],
    TR: ['Turkey'],
    TW: ['Taiwan'],
    UA: ['Ukraine'],
    US: ['United States', 'USA'],
    VN: ['Vietnam'],
    ZA: ['South Africa']
};
